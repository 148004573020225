
import LogosCarousel from '~/components/LogosCarousel.vue'
export default {
  name: 'AwardsSlice',
  components: { LogosCarousel },
  data() {
    return {
      title: {
        en: `Awards`,
        it: `Premi`
      },
      items: [
        {
          url: '/img/logos/reddot-2024.svg',
          alt: 'Reddot Award',
          text: {
            en: `Reddot Winner 2024`,
            it: `Reddot Winner 2024`,
            de: `Reddot Winner 2024`
          },
          dimensions: { width: 120, height: 58 }
        },
        {
          url: '/img/logos/european-product-design-award.svg',
          alt: 'European Product Design Award',
          text: {
            en: `Winner Design For Society`,
            it: `Winner Design For Society`,
            de: `Winner Design For Society`
          },
          dimensions: { width: 66, height: 58 }
        },
        {
          url: '/img/logos/good-design.svg',
          alt: 'Good Design',
          text: {
            en: `Winner Household Products`,
            it: `Winner Household Products`,
            de: `Winner Household Products`
          },
          dimensions: { width: 62, height: 60 }
        },
        {
          url: '/img/logos/german-design-awards-2018.svg',
          alt: 'German Design Award Winner 2018',
          text: {
            en: `Winner Excellent Product Design Household`,
            it: `Winner Excellent Product Design Household`,
            de: `Winner Excellent Product Design Household`
          },
          dimensions: { width: 116, height: 64 }
        },
        {
          url: '/img/logos/a-design-award.svg',
          alt: 'A Design Award & Competition',
          text: {
            en: `Winner Home Appliances Design`,
            it: `Winner Home Appliances Design`,
            de: `Winner Home Appliances Design`
          },
          dimensions: { width: 42, height: 65 }
        },
        {
          url: '/img/logos/CSEMEA-2023.png',
          alt: 'Channel Summit EMEA 2023',
          text: {
            en: `Best Product Smart Home and Living`,
            it: `Best Product Smart Home and Living`,
            de: `Best Product Smart Home and Living`
          },
          dimensions: { width: 65, height: 65 }
        }
      ],
    }
  }
}
